/* 이미지 컨테이너 스타일링 */
.aspect-w-16 {
  position: relative;
  padding-bottom: 75%; /* 4:3 비율로 변경 */
  height: 0;
  overflow: hidden;
  background: #f8fafc;
}

.aspect-w-16 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* cover에서 contain으로 변경 */
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.aspect-w-16:hover img {
  transform: scale(1.05);
}

/* 모바일 최적화 */
@media (max-width: 768px) {
  .aspect-w-16 {
    padding-bottom: 75%; /* 모바일에서는 4:3 비율로 변경 */
  }

  .aspect-w-16 img {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 640px) {
  .grid {
    gap: 1rem;
  }

  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.1),
                0 2px 4px -1px rgba(59, 130, 246, 0.06);
  }

  .grid-cols-3 {
    gap: 0.5rem;
  }
  
  .p-4 {
    padding: 0.75rem;
  }

  .max-w-3xl {
    width: 95%;
    margin: 0 auto;
  }
  
  .max-h-70vh {
    max-height: 50vh; /* 모바일에서 더 작게 조정 */
  }

  /* 모바일에서 내비게이션 버튼 크기 조정 */
  .modal-nav-button svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

/* 호버 효과 */
.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: translateY(-2px);
}

/* 이미지 로딩 스켈레톤 */
.aspect-w-16::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #f3f4f6 0%, #e5e7eb 50%, #f3f4f6 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* 이미지 로딩 상태 개선 */
.aspect-w-16.bg-gray-100 {
  background: linear-gradient(110deg, #f5f5f5 8%, #ffffff 18%, #f5f5f5 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes shimmer {
  to {
    background-position: -200% center;
  }
}

/* 카드 스타일 개선 */
.bg-white {
  background: white;
}

.rounded-xl {
  border-radius: 1rem;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* 모달 애니메이션 */
@keyframes modalFade {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fixed {
  animation: modalFade 0.2s ease-out;
}

/* 이미지 호버 효과 */
.aspect-w-16:hover::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

/* 모달 스타일링 */
.modal-image-container {
  position: relative;
  width: 90vw;
  max-width: 800px; /* 최대 너비 제한 */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.modal-image-container img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 30vh; /* 데스크탑 기본 높이 */
  object-fit: contain;
}

@media screen and (min-width: 641px) {
  .modal-image-container img {
    max-height: 70vh;
  }
}

/* 모달 배경 */
.modal-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  padding: 1rem;
}

/* 이미지 그리드 스타일링 */
.aspect-square {
  position: relative;
  padding-bottom: 100%;
}

.aspect-square img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* 반응형 그리드 간격 */
@media (max-width: 640px) {
  .grid.grid-cols-3 {
    gap: 0.5rem;
  }
  
  .aspect-square img {
    padding: 0.25rem;
  }
}

/* 이미지 호버 효과 최적화 */
.group:hover img {
  transform: scale(1.05);
}

/* 모달 내 이미지 스타일링 */
.modal-image-container img {
  max-width: 100%;
  /* max-height: 90vh; */
  object-fit: contain;
  margin: auto;
}

/* 모달 내비게이션 버튼 */
.modal-nav-button {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.modal-nav-button:hover {
  opacity: 1;
}