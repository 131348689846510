/* Features.css */

/* 전체 컨테이너 스타일 */
.features-container {
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(135deg, #f3f4f6, #e3e6ea); /* 배경 그라데이션 추가 */
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  box-sizing: border-box;
}

/* 제목 스타일 */
.features-title {
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333;
}

.features-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

/* 이미지 다이어그램 스타일 */
.features-image {
  margin: 20px auto 40px;
  max-width: 600px;
}

.features-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 카드 그리드 */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

/* 카드 스타일 */
.feature-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 2.5rem;
  color: #4285f4;
  margin-bottom: 10px;
}

.feature-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.feature-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.4;
}

/* 반응형 스타일 */

/* 태블릿 화면 */
@media (max-width: 768px) {
  .features-title {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr; /* 한 열로 정렬 */
  }

  .features-image {
    max-width: 400px;
  }
}

/* 모바일 화면 */
@media (max-width: 480px) {
  .features-title {
    font-size: 1.8rem;
  }

  .features-image {
    max-width: 100%;
  }

  .feature-card {
    padding: 15px;
  }

  .feature-icon {
    font-size: 2rem;
  }
}
