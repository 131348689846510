.footer {
  background-color: #1f2937; /* 어두운 블루/그레이 베이스 */
  color: #d1d5db; /* 밝은 그레이 텍스트 */
  padding: 20px 30px;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* 반응형 지원 */
}

.company-info {
  flex: 1;
  font-size: 14px;
  line-height: 1.8;
}

.company-info p {
  margin: 5px 0;
}

.copyright-info {
  text-align: right;
  flex: 1;
  font-size: 14px;
  line-height: 1.8;
}

.footer a {
  color: #38bdf8; /* 강조된 밝은 블루 */
  text-decoration: none;
}

.footer a:hover {
  color: #a5f3fc; /* 호버 시 더 밝은 블루 */
  text-decoration: underline;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .company-info,
  .copyright-info {
    text-align: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px;
  }

  .company-info p,
  .copyright-info p {
    font-size: 13px;
  }

  .footer a {
    display: inline-block;
    margin: 5px 0;
  }
}
