/* Title Styling */
h1 {
  text-align: center;
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

/* 테이블 컨테이너 */
.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; /* 수직 정렬 */
}

/* Table Styling */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  min-width: 650px;
}

.table th,
.table td {
  text-align: left;
  padding: 12px 15px;
  border: none;
  color: #555;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

.table th {
  background-color: #f1f1f1;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  background: #f8f9fa;
  font-weight: 600;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f3f3f3;
  transition: background-color 0.2s ease;
  background-color: #f5f5f5;
}

.table td a {
  color: #2ecc71; /* Matching the green theme */
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.table td a:hover {
  text-decoration: underline;
  color: #27ae60;
}

/* Footer Styling */
footer {
  background-color: #2ecc71;
  color: #fff;
  text-align: center;
  padding: 15px 20px;
  font-size: 0.9rem;
}

footer a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

footer a:hover {
  text-decoration: underline;
}



.board-container {
  max-width: 1600px; /* 통일된 넓이 */
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* 헤더 영역 */
.board-detail-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 20px;
}

.board-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 16px;
}

.board-meta {
  display: flex;
  gap: 24px;
  color: #6b7280;
  font-size: 0.95rem;
}

.board-meta span {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

/* 본문 스타일 */
.prose {
  font-size: 1.1rem;
  line-height: 2; /* 1.75에서 2로 증가 */
  color: #374151;
}

.prose p {
  margin-bottom: 1.8em; /* 1.25em에서 1.8em으로 증가 */
}

.prose img {
  max-width: 100%;
  height: auto;
  border-radius: 0.375rem;
}

.prose h2 {
  font-size: 1.5em;
  margin-top: 2.5em; /* 2em에서 2.5em으로 증가 */
  margin-bottom: 1.2em; /* 1em에서 1.2em으로 증가 */
  font-weight: 600;
  color: #1f2937;
}

.prose h3 {
  font-size: 1.25em;
  margin-top: 2em; /* 1.6em에서 2em으로 증가 */
  margin-bottom: 1em; /* 0.8em에서 1em으로 증가 */
  font-weight: 600;
  color: #1f2937;
}

/* 리스트 아이템 간격 조정 */
.prose ul, .prose ol {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* 테이블 스타일 */
.prose table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5em;
  overflow-x: auto;
}

.prose th, .prose td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.prose th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.prose td {
  background-color: #ffffff;
}

/* 버튼 스타일 */
.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #2563eb;
  color: white;
}

.btn-primary:hover {
  background-color: #1d4ed8;
}

.btn-danger {
  background-color: #dc2626;
  color: white;
}

.btn-danger:hover {
  background-color: #b91c1c;
}

.btn-secondary {
  background-color: #4b5563;
  color: white;
}

.btn-secondary:hover {
  background-color: #374151;
}

/* 푸터 영역 */
.board-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e5e7eb;
}

.board-footer-buttons {
  display: flex;
  gap: 12px;
}

/* Editor Container Styles */
.editor-container {
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #f9f9f9; /* Subtle light gray for a modern look */
  border: 1px solid #d1d9e6; /* Softer border color */
  border-radius: 12px; /* Slightly more rounded corners for a smoother feel */
  padding: 20px; /* Increased padding for better spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Input Field Styles */
.form-control {
  font-size: 1.2rem; /* Slightly larger font for readability */
  padding: 12px; /* More padding for a comfortable input area */
  border: 1px solid #ced4da; /* Neutral border color */
  border-radius: 8px; /* Rounded corners for a modern feel */
  background-color: #ffffff; /* Clean white background */
  transition: all 0.3s ease-in-out; /* Smooth transition effects */
}

/* Input Field Focus Styles */
.form-control:focus {
  outline: none;
  border-color: #4a90e2; /* Bright blue to indicate focus */
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.5); /* Glow effect on focus */
}

/* Placeholder Text Styling (Optional) */
.form-control::placeholder {
  color: #a0a4ab; /* Subtle gray for placeholder text */
  font-style: italic; /* Adds a touch of style to placeholder text */
}

/* Add Hover Effect on Input Fields (Optional) */
.form-control:hover {
  border-color: #b0bec5; /* Slightly darker border on hover */
}

.ck-content {
  line-height: 1.8;
  font-size: 16px;
}

.ck-content p {
  margin: 1.2em 0;
}

.ck-content h2 {
  margin-top: 1.8em;
  margin-bottom: 1em;
}

.ck-content h3 {
  margin-top: 1.5em;
  margin-bottom: 0.8em;
}

/* 이미지 스타일 */
.ck-content img {
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}

.ck-content figure.image {
  margin: 1.5em 0;
}

.ck-content figure.image img {
  margin: 0;
}

.ck-content figure.image figcaption {
  font-size: 0.9em;
  color: #666;
  text-align: center;
  margin-top: 0.5em;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  .table th,
  .table td {
    font-size: 0.9rem;
    padding: 10px;
    vertical-align: middle;
  }

  .btn-primary,
  .btn-danger {
    font-size: 14px;
    padding: 10px 15px;
  }

  .table {
    min-width: 500px;
  }
  
  .table th,
  .table td {
    padding: 8px;
    font-size: 14px;
  }

  /* 조회수 컬럼 숨기기 */
  .table th:nth-child(5),
  .table td:nth-child(5) {
    display: none;
  }

  .board-detail-container {
    padding: 1rem;
  }

  .board-detail-wrapper {
    max-width: 100%;
    padding: 20px;
  }

  .board-meta {
    flex-direction: column;
    gap: 8px;
  }

  .board-footer {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .board-footer-buttons {
    width: 100%;
    justify-content: flex-end;
  }

  .btn {
    padding: 8px 12px;
  }

  .board-detail-content {
    padding: 20px 0;
  }
  
  .prose {
    font-size: 1rem;
    line-height: 1.8;
  }

  h1.text-3xl {
    font-size: 1.5rem;
  }

  .text-sm {
    font-size: 0.813rem;
  }

  .prose img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  .px-4 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .p-8 {
    padding: 1.25rem;
  }

  figure {
    margin: 1rem 0;
    max-width: 100% !important;
  }

  figure img {
    width: 100% !important;
    height: auto !important;
  }

  iframe {
    max-width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }

  .board-detail-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .board-detail-meta {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .board-detail-meta span {
    margin-bottom: 0.5rem;
  }

  .board-detail-content {
    font-size: 1rem;
  }

  .board-detail-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .board-detail-buttons button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .table {
    min-width: 400px;
  }

  /* 작성날짜 컬럼 숨기기 */
  .table th:nth-child(4),
  .table td:nth-child(4) {
    display: none;
  }

  .table th,
  .table td {
    padding: 6px;
    font-size: 13px;
  }

  .btn {
    padding: 6px 12px;
    font-size: 13px;
  }

  .prose {
    font-size: 0.938rem;
  }

  h1.text-3xl {
    font-size: 1.25rem;
  }

  .text-sm {
    font-size: 0.75rem;
  }

  .mr-2 {
    margin-right: 0.375rem;
  }

  svg {
    width: 1.375rem;
    height: 1.375rem;
  }

  .p-8 {
    padding: 1rem;
  }

  .space-x-6 > * + * {
    margin-left: 1rem;
  }
}

/* 모바일 반응형 스타일 */
@media (max-width: 640px) {
  .overflow-x-auto {
    -webkit-overflow-scrolling: touch; /* iOS 스크롤 부드럽게 */
    margin: 0 -1rem;
    padding: 0 1rem;
  }
  
  /* 테이블 헤더와 셀 크기 조정 */
  .px-6 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  /* 테이블 내용 글자 크기 조정 */
  .text-sm {
    font-size: 0.813rem;
  }
  
  /* 페이지네이션 버튼 크기 조정 */
  .pagination-button {
    padding: 0.5rem 0.75rem;
  }

  /* 페이지네이션 스타일링 */
  .pagination-nav {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
  }

  .pagination-nav button {
    min-width: 2rem;
    padding: 0.5rem;
  }

  /* 헤더 스타일링 */
  .text-3xl {
    font-size: 1.5rem;
  }

  /* 버튼 스타일링 */
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  /* 테이블 셀 패딩 조정 */
  .py-4 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  /* 모바일에서 조회수 표시 간격 조정 */
  .flex.items-center.mt-1 span:last-child {
    margin-left: 0.75rem;
    min-width: 4rem; /* 조회수 텍스트 영역 최소 너비 설정 */
  }
}

/* 스크롤바 스타일링 (선택사항) */
.overflow-x-auto::-webkit-scrollbar {
  height: 6px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* iframe 및 미디어 요소 */
iframe, 
figure img {
  max-width: 100% !important;
  height: auto !important;
}

iframe {
  aspect-ratio: 16/9;
}

figure {
  margin: 1rem 0;
  max-width: 100% !important;
}
