/* General section styling */
section {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 15px;
}

.home section {
  scroll-margin-top: 5rem;
}

.home .shadow-lg:hover {
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

/* 애니메이션 효과 */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home section > * {
  animation: fadeInUp 0.6s ease-out;
}

/* Service Section specific styling */
.service-section {
  border-left: 5px solid #4caf50; /* Green accent */
}

/* Board Section specific styling */
.board-section {
  border-left: 5px solid #2196f3; /* Blue accent */
}

/* Schedule Section specific styling */
.schedule-section {
  border-left: 5px solid #ff9800; /* Orange accent */
}

/* Add responsive design */
@media (max-width: 768px) {
  section {
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .home section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .home h2 {
    font-size: 1.075rem;
  }
  
  .popup-content {
    font-size: 0.875rem; /* 폰트 크기를 조정 */
  }

  .popup-content h2 {
    font-size: 1.3rem; /* 제목 폰트 크기를 조정 */
  }

  .popup-content p {
    font-size: 0.875rem; /* 본문 폰트 크기를 조정 */
  }

  .popup-content button {
    font-size: 0.875rem; /* 버튼 폰트 크기를 조정 */
  }

  /* 추가된 반응형 폰트 크기 조정 */
  .home h3 {
    font-size: 1rem; /* 모바일에서 h3 폰트 크기 조정 */
  }

  .home p {
    font-size: 0.875rem; /* 모바일에서 p 폰트 크기 조정 */
  }

  .home a {
    font-size: 0.875rem; /* 모바일에서 링크 폰트 크기 조정 */
  }
}
