.workway-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f4f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: auto;
  margin-right: 45px;
}

.header-text {
  text-align: left;
}

.header-text h1 {
  font-size: 2rem;
  margin: 0;
  color: #333;
}

.header-text p {
  font-size: 1rem;
  margin: 5px 0 0;
  color: #666;
}

/* Intro Section */
.workway-intro {
  background-color: #e6eaf0;
  padding: 40px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}

.workway-intro h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.workway-intro p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Diagram Section */
.workway-diagram {
  background-color: #ffffff;
  padding: 40px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.diagram-image {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
}

/* Features Section */
.workway-features {
  background-color: #f9f9f9;
  padding: 40px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
}


/* Contact Section */
.workway-contact {
  background-color: #f7f9fc;
  padding: 40px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
}

.workway-contact h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.workway-contact p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-link {
  display: inline-block;
  background-color: #5e35b1;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;
}

.contact-link:hover {
  background-color: #452c84;
}

/* 기존 스타일 유지 */

/* 반응형 웹 스타일 추가 */
@media (max-width: 1024px) {
  .workway-header {
    flex-direction: column; /* 헤더를 세로 정렬 */
    text-align: center;
  }

  .logo {
    margin: 0 0 10px 0; /* 로고 간격 조정 */
  }

  .header-text h1 {
    font-size: 1.8rem; /* 글자 크기 줄임 */
  }

  .header-text p {
    font-size: 0.9rem;
  }

  .workway-diagram,
  .workway-intro,
  .workway-features,
  .workway-contact {
    padding: 20px; /* 패딩 축소 */
  }

  .diagram-image {
    width: 90%; /* 이미지 너비 조정 */
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* 카드 세로 정렬 */
    align-items: center;
  }

  .card {
    flex-basis: 80%; /* 카드 너비 조정 */
  }

  .workway-intro p,
  .workway-contact p {
    font-size: 0.95rem; /* 본문 글자 크기 줄임 */
  }

  .workway-intro h2,
  .workway-contact h2,
  .workway-features h2 {
    font-size: 1.5rem; /* 섹션 제목 크기 조정 */
  }

  .contact-link {
    font-size: 0.9rem; /* 버튼 글자 크기 조정 */
    padding: 8px 16px; /* 버튼 패딩 축소 */
  }
}

@media (max-width: 480px) {
  .workway-header {
    padding: 15px; /* 헤더 패딩 축소 */
  }

  .header-text h1 {
    font-size: 1.6rem; /* 타이틀 글자 크기 줄임 */
  }

  .header-text p {
    font-size: 0.8rem; /* 서브 타이틀 글자 크기 줄임 */
  }

  .diagram-image {
    width: 100%; /* 이미지가 화면에 꽉 차게 */
  }

  .card {
    padding: 15px; /* 카드 내부 패딩 축소 */
    font-size: 0.9rem;
  }

  .card h3 {
    font-size: 1rem; /* 카드 타이틀 글자 크기 조정 */
  }

  .card p {
    font-size: 0.85rem; /* 카드 본문 글자 크기 조정 */
  }

  .workway-contact p {
    font-size: 0.85rem; /* 문의 섹션 본문 글자 크기 조정 */
  }
}
