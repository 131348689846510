/* 1. 기본 컨테이너 스타일 */
.navbar-container {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #eee;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  gap: 3rem;
}

/* 2. 로고 스타일 */
.navbar-logo {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 2rem;
}

.navbar-logo img {
  height: 100px;
  width: auto;
}

/* 3. 네비게이션 링크 기본 스타일 */
.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1;
}

.nav-link {
  padding: 0.5rem 0.75rem;
  color: #4b5563;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 0.375rem;
  font-size: 0.975rem;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

/* 4. 활성 메뉴 및 호버 효과 */
.nav-link.active {
  color: #2563eb;
  background-color: #eff6ff;
  font-weight: 500;
}

.nav-link:hover:not(.active) {
  color: #2563eb;
  background-color: #f8fafc;
}

/* 5. 사용자 정보 스타일 */
.user-info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
}

.user-info-dropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  min-width: 200px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
  overflow: hidden;
  animation: slideUpMenu 0.2s ease-out;
}

/* 6. 모바일 메뉴 스타일 */
.hamburger-icon {
  display: none;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  color: #4b5563;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 서브메뉴 스타일 */
.nav-links li {
  position: relative;
}

.nav-links li ul {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

.nav-links li:hover ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* 기존 미디어 쿼리를 수정 */
@media screen and (max-width: 1300px) {
  .navbar {
    padding: 0.5rem 1rem;
    height: 60px;
    gap: 1.5rem;
  }

  .nav-links {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: auto;
    max-height: calc(100vh - 60px);
    background-color: white;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-links.mobile-open {
    display: flex;
    z-index: 1000;
  }

  .hamburger-icon {
    display: flex;
  }

  .auth-section {
    display: none !important;
  }

  .mobile-user-btn {
    display: flex;
    margin-right: 0.5rem;
  }

  /* 모바일 메뉴 아이템 스타일 */
  .nav-links li {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
  }

  .nav-links li:last-child {
    border-bottom: none;
  }

  .nav-links li a,
  .nav-links li button {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 1rem;
  }

  .navbar-logo img {
    height: 40px;
  }
}

/* 모바일 대응 */
@media (max-width: 1024px) {
  .nav-links li ul {
    position: static;
    display: none;
    box-shadow: none;
    padding-left: 1rem;
  }

  .nav-links li:hover ul {
    display: block;
  }
}

/* 모바일 메뉴 스타일 */
@media (max-width: 768px) {
  .nav-item {
    width: 100%;
    padding: 0;
  }

  .submenu-container {
    display: none;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin-top: 4px;
  }

  .submenu-container.block {
    display: block;
  }

  .nav-link {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* 8. 애니메이션 */
@keyframes slideUpMenu {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
